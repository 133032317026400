import React, { useCallback } from 'react';
import type { CourseTableItem } from '../CoursesTable';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { NextLink } from 'common';

const useStyles = makeStyles((theme) => ({
	styledLink: {
		color: theme.palette.secondary.main,
		fontWeight: '700',
		textDecoration: 'underline',

		[theme.breakpoints.down('md')]: {
			fontSize: 14
		}
	}
}));

type MouseClickEventType =
	| React.MouseEvent<HTMLAnchorElement, MouseEvent>
	| React.MouseEvent<HTMLSpanElement, MouseEvent>;

type CourseOtherSchedulesLinkProps = Pick<CourseTableItem, 'totalCoursesForProduct' | 'productURL'>;

export const CourseOtherSchedulesLink = ({
	totalCoursesForProduct,
	productURL
}: CourseOtherSchedulesLinkProps) => {
	const classes = useStyles();
	const { formatMessage } = useIntl();

	const stopPropagationToAccordionHeader = useCallback((event: MouseClickEventType) => {
		event.stopPropagation();
	}, []);

	const parsedCoursesCount = Number.parseInt(totalCoursesForProduct);
	if (parsedCoursesCount > 1) {
		const coursesCountWithoutCurrentCourse = parsedCoursesCount - 1;

		const text =
			coursesCountWithoutCurrentCourse === 1
				? formatMessage(
						{ id: 'media-library.home.courses-further-dates.singular-form' },
						{
							coursesCount: coursesCountWithoutCurrentCourse
						}
				  )
				: formatMessage(
						{ id: 'media-library.home.courses-further-dates.plural-form' },
						{
							coursesCount: coursesCountWithoutCurrentCourse
						}
				  );

		return (
			<NextLink
				className={classes.styledLink}
				href={productURL}
				onClick={stopPropagationToAccordionHeader}
			>
				{text}
			</NextLink>
		);
	}

	return null;
};
